import React, { useEffect, useState } from "react";
import sanityClient from "./sanity";
import imageUrlFor from "./components/imageUrlFor";
import "react-multi-carousel/lib/styles.css";
import { useParams } from "react-router-dom";
import Navbar from "./components/navbar";
import Footer from "./components/Footer";
import { PortableText } from "@portabletext/react";
import moment from "moment";
import BlockContent from "@sanity/block-content-to-react";

export const myPortableTextComponents = {
  types: {
    image: ({ value }) => <img src={value.imageUrl} />,
    callToAction: ({ value, isInline }) =>
      isInline ? (
        <a href={value.url}>{value.text}</a>
      ) : (
        <div className="callToAction">{value.text}</div>
      ),
  },
  block: {
    // Ex. 1: customizing common block types
    h1: ({ children }) => <h1 className="text-2xl">{children}</h1>,
    blockquote: ({ children }) => (
      <blockquote className="border-l-purple-500">{children}</blockquote>
    ),

    // Ex. 2: rendering custom styles
    customHeading: ({ children }) => (
      <h2 className="text-lg text-primary text-purple-700">{children}</h2>
    ),
  },
  listItem: {
    // Ex. 1: customizing common list types
    bullet: ({ children }) => (
      <li style={{ listStyleType: "disclosure-closed" }}>{children}</li>
    ),

    // Ex. 2: rendering custom list items
    checkmarks: ({ children }) => <li>✅ {children}</li>,
  },
  list: {
    // Ex. 1: customizing common list types
    bullet: ({ children }) => <ul className="mt-xl">{children}</ul>,
    number: ({ children }) => <ol className="mt-lg">{children}</ol>,

    // Ex. 2: rendering custom lists
    checkmarks: ({ children }) => (
      <ol className="m-auto text-lg">{children}</ol>
    ),
  },

  marks: {
    link: ({ children, value }) => {
      const rel = !value.href.startsWith("/")
        ? "noreferrer noopener"
        : undefined;
      return (
        <a href={value.href} rel={rel}>
          {children}
        </a>
      );
    },
  },
};
const BlogPage = () => {
  const formattedDate = (date) => {
    const formatDate = moment(date).format("MMMM Do YYYY");
    return formatDate;
  };

  //   const BlockContent = require("@sanity/block-content-to-react");

  const { slug } = useParams();
  const [blogs, setBlogs] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post" && slug.current=="${slug}"]{
          title,
          slug,
    mainImage{
            asset{
              _ref
            }
          },
     publishedAt,
          body,
          image{
            asset->{
              _ref,
              url
            },
          },
         
        }`
      )
      .then((data) => setBlogs(data))
      .catch(console.error);

    console.log(blogs);
  });
  const BlockRenderer = (props) => {
    const { style = "normal" } = props.node;

    if (/^h\d/.test(style)) {
      const level = style.replace(/[^\d]/g, "");
      return React.createElement(
        style,
        { className: `heading-${level}` },
        props.children
      );
    }

    if (style === "blockquote") {
      return <blockquote>- {props.children}</blockquote>;
    }

    // Fall back to default handling
    return BlockContent.defaultSerializers.types.block(props);
  };
  return (
    <div>
      <Navbar />
      <div className=" pb-20 lg:mx-[140px] md:mx-16 sm:mx-8">
        <div>
          {blogs &&
            blogs.map((blog, index) => (
              <div
                key={index}
                className=" p-3 flex justify-center flex-col items-center"
              >
                <h2 className="text-lg md:text-xl lg:text-2xl font-semibold mb-4 md:mb-6 title mt-5 text-left">
                  {blog.title}
                </h2>
                <img
                  src={imageUrlFor(blog.mainImage.asset._ref).url()}
                  alt=""
                  className="h-[200px] md:h-[350px] mb-[24px] md:mb-[48px]  "
                />

                {/* Make sure you in stall the tailwind/typography plugin */}
                <div className="prose text-gray-600 mb-[24px] md:mb-[48px]  ">
                <PortableText
                    value={blog.body}
                    //components={myPortableTextComponents}
                  />
                  {/* <BlockContent
                    blocks={blog.body}
                    projectId="kdljv30m"
                    dataset="production"
                    serializers={{ types: { block: BlockRenderer } }}
                  /> */}
                </div>
                
                <h3 className="text-gray-600">
                  Published: {formattedDate(blog.publishedAt)}
                </h3>
              </div>
            ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogPage;
