import React, { useEffect, useState } from "react";
import sanityClient from "./sanity";
import imageUrlFor from "./components/imageUrlFor";
import "react-multi-carousel/lib/styles.css";
import Navbar from "./components/navbar";
import Footer from "./components/Footer";
import { Link } from "react-router-dom";
import moment from "moment";

const AllBlogs = () => {
  const BlockContent = require("@sanity/block-content-to-react");

  const [blogs, setBlogs] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
              title,
              slug,
        mainImage{
                asset{
                  _ref
                }
              },
         publishedAt,
              body,
              image{
                asset->{
                  _ref,
                  url
                },
              },
             
            }`
      )
      .then((data) => setBlogs(data))
      .catch(console.error);

    // Simulate a 5-second delay
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => {
      clearTimeout(loadingTimeout);
    };
  }, []);
  const formattedDate = (date) => {
    const formatDate = moment(date).format("MMMM Do YYYY");
    return formatDate;
  };

  return (
    <div>
      <Navbar />
      <section className=" px-[16px] md:px-[60px] md:gap-4 lg:px-[120px] mt-[40px] md:mt-[60px] lg:mt-[80px] relative">
        <img src="./image/lightup.png" alt="light up" className="mx-auto " />
      </section>
      <section className=" px-[16px] md:px-[60px] md:gap-4 lg:px-[120px] mt-[32px] md:mt-[50px] lg:mt-[60px] relative mb-[60px] md:mb-[80px] lg:mb-[120px]">
        <h2 className="w-full md:w-[65%] text-[18px] md:text-[24px] lg:text-[32px] lg:mb-[120px] md:mb-[80px] mb-[40px] font-medium">
          Unlocking the Power of Knowledge:
          <br /> Insights and blog to inform and inspire
        </h2>
      </section>
      {isLoading ? (
        "loading..."
      ) : (
        <div className=" mb-[60px] md:mb-[60px] px-[16px] md:px-[30px] lg:px-[80px]">
          {blogs && (
            blogs.map((blog, index) => (
              <div className="flex md:flex-row flex-col gap-[20px] mb-8">
                <div className="w-full md:w-[50%] ">
                  <img
                    src={imageUrlFor(blog.mainImage.asset._ref).url()}
                    alt="charlie"
                    className="mx-auto h-[300px] "
                  />
                </div>
                <div className="w-full md:w-[50%]">
                  <div className="flex items-center text-[14px] gap-[24px] mb-[20px] md:mb-[28px] lg:mb-[32px]">
                    <p>                   {formattedDate(blog.publishedAt)}
                    </p>
                    <p>By admin</p>
                    {/* <p>Money Tips</p> */}
                  </div>
                  <Link to={"/allblog/" + blog.slug.current}>

                  <h3 className="text-[18px] md:text-[20px] lg:text-[24px] font-semibold mb-[12px]">
                    {blog.title}
                  </h3>
                  </Link>
                  <div className="prose line-clamp-4">
                    <BlockContent
                      blocks={blog.body}
                      projectId="pzl9rov9"
                      dataset="production"
                    />
                  </div>

                  <Link to={"/allblog/" + blog.slug.current}>
                  <button className="border border-[#B9B9B9] text-xs hover:bg-slate-100  rounded-lg px-2 py-1 md:px-3 md:py-2 mt-[26px] md:mt-[32px]">
                    read more
                  </button>
                </Link>
                </div>

                
              </div>
            ))
          ) }
        </div>
      )}

      <Footer />
    </div>
  );
};

export default AllBlogs;
